export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const LOGOUT = "LOGOUT";

export const ADD_NEW_LINE_TO_LIST = "ADD_NEW_LINE_TO_LIST"
export const REMOVE_LAST_LINE_FROM_LIST = "REMOVE_LAST_LINE_FROM_LIST"
export const RESET_LIST_NEW_LINES = "RESET_LIST_NEW_LINES"
export const GET_OPTIONS_SELECTED_FORM = "GET_OPTIONS_SELECTED_FORM"
export const REMOVE_METHOD_AND_CHANGE_NEW_LIST = "REMOVE_METHOD_AND_CHANGE_NEW_LIST"


export const GET_EXERCISE = "GET_EXERCISE"
export const SET_LIST_PROPOSITONS = "SET_LIST_PROPOSITONS"

export const ADD_NEW_LINES_TO_LIST = "ADD_NEW_LINES_TO_LIST"
export const CREATE_SESSION_EXERCISE = "CREATE_SESSION_EXERCISE"


export const CHECK_USER_SUCESS = "CHECK_USER_SUCESS"
export const CHECK_USER_FAIL = "CHECK_USER_FAIL"

export const GET_CLASSROOM_SUCCESS = "GET_CLASSROOM_SUCCESS"
export const GET_CLASSROOM_FAIL = "GET_CLASSROOM_FAIL"

export const REGISTER_CLASSROOM_SUCCESS = "REGISTER_CLASSROOM_SUCCESS"
export const REGISTER_CLASSROOM_FAIL = "REGISTER_CLASSROOM_FAIL"

export const LOGIN_TRUE = "LOGIN_TRUE"
export const LOGIN_FALSE = "LOGIN_FALSE"

export const SET_STATUS_CODE = "SET_STATUS_CODE"

export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS"
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL"

export const GET_ALL_PROBLEM_SUCCESS = "GET_ALL_PROBLEM_SUCCESS"
export const GET_ALL_PROBLEM_FAIL = "GET_ALL_PROBLEM_FAIL"


export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS"
export const GET_ALL_STUDENTS_FAIL = "GET_ALL_STUDENTS_FAIL"

